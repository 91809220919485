import { RefObject, useEffect } from "react"

export const useOnClickOutside = (refs: RefObject<HTMLElement>[], handler: (event: any) => void) => {
  useEffect(() => {
    const listener = (event: { target: any }) => {
      const hasNoCurrentRefs = refs.every((ref) => !ref.current)
      const refsContainEventTarget = refs.some((ref) => ref.current?.contains(event.target))

      if (hasNoCurrentRefs || refsContainEventTarget) return

      handler(event)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [refs, handler])
}
