import { useField } from "formik"
import { FC, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { classNames } from "../../../helpers/classNames"
import { pluralize } from "../../../helpers/pluralize"
import { isOneDimensional } from "../../../helpers/util-functions"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { usePinElements } from "../../../hooks/usePinElements"
import { TailwindIcon } from "../../../types/tailwind"
import { Label } from "../../Elements"
import { MultiSelectBody } from "./MultiSelectBody"
import { MultiSelectInputBar } from "./MultiSelectInputBar"
import { MultiSelectOptionElement } from "./MultiSelectOptionElement"
import { MultiSelectTable } from "./MultiSelectTable"
import { SelectAll } from "./SelectAll"

export type MultiSelectOption = {
  id: string
  label: string
  value: string
  searchableTextString: string
  template?: (item: MultiSelectOption) => JSX.Element
  disabled?: boolean
}

type Props = {
  name: string
  Icon?: TailwindIcon
  label?: string
  isSingleSelect?: boolean
  isCreatable?: boolean
  options: MultiSelectOption[] | MultiSelectOption[][]
  optionGroupTitles?: string[]
  disabled?: boolean
  placeholder?: string
  required?: boolean
  optionGroupHeadingTemplate?: (title: string) => ReactNode
  formatCreateLabel?: (inputValue: string) => ReactNode
  selectedTemplate?: (items: MultiSelectOption[]) => JSX.Element
  selectedLabel?: string
  withSelectAll?: boolean
  containerClassName?: string
  labelClassName?: string
  onChange?: (selectedValues: string[]) => void
  onCreate?: (inputValue: string) => void
  withErrorHandling?: boolean
}

const RowTemplate = (item: MultiSelectOption) => <div>{item.label}</div>

export const MultiSelect: FC<Props> = ({
  name,
  Icon,
  label = "",
  isSingleSelect = false,
  isCreatable = false,
  options,
  optionGroupTitles = [],
  disabled = false,
  placeholder,
  required,
  optionGroupHeadingTemplate,
  formatCreateLabel = (inputValue: string) => `“${inputValue}” (Create new)`,
  selectedTemplate,
  selectedLabel,
  withSelectAll = false,
  containerClassName,
  labelClassName,
  onChange = () => {},
  onCreate,
  withErrorHandling = true,
}) => {
  const [field, meta, helpers] = useField(name)
  const [inputRef, bodyRef] = usePinElements<HTMLDivElement, HTMLDivElement>()
  const [searchText, setSearchText] = useState("")
  const [matchedOptions, setMatchedOptions] = useState(options)
  const [selectedOptions, setSelectedOptions] = useState(options)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [matched, setMatched] = useState(false)
  const isGrouped = !isOneDimensional(options)

  useOnClickOutside([inputRef, bodyRef], () => setModalIsOpen(false))

  useEffect(() => {
    if (field.value && field.value.length && isSingleSelect) {
      setModalIsOpen(false)
    }
  }, [field.value, isSingleSelect])

  useEffect(() => {
    if (isSingleSelect) {
      setSelectedOptions([[options.flat().find((item) => item.value === field.value) || ({} as MultiSelectOption)]])
      return
    }
    setSelectedOptions([
      options.flat().filter((item) => field.value.find((itemValue: string) => itemValue === item.value)),
    ])
  }, [name, isSingleSelect, options, field.value])

  useEffect(() => {
    let isSame = false
    const itemListContainsSearchText = ({ searchableTextString }: MultiSelectOption) => {
      if (searchableTextString === searchText) isSame = true
      return searchableTextString.toLowerCase().includes(searchText.toLowerCase())
    }
    const matchedItems: MultiSelectOption[] | MultiSelectOption[][] = isGrouped
      ? (options as MultiSelectOption[][])
          .map((itemGroup) => itemGroup.filter(itemListContainsSearchText))
          .filter((itemGroup) => itemGroup.length)
      : (options as MultiSelectOption[]).filter(itemListContainsSearchText)
    setMatchedOptions(matchedItems)
    setMatched(isSame)
  }, [searchText, options, field.value, isGrouped])

  useEffect(() => {
    onChange(field.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  const selectedItems = useMemo((): MultiSelectOption[] => {
    return options
      .flat()
      .filter((option) => field.value?.some((selectedValue: string) => selectedValue === option.value))
  }, [field.value, options])

  const onSelectCreate = useCallback(
    (value: string) => {
      setSearchText("")
      onCreate?.(value)
    },
    [onCreate]
  )

  const onClearInput = useCallback(
    (event: MouseEvent<SVGElement>) => {
      event.stopPropagation()
      if (searchText) setSearchText("")
      else helpers.setValue(undefined)
    },
    [helpers, searchText]
  )

  const renderCreateNewOption = useMemo(
    () => (
      <div key={`itemGroup-new`} className={"py-2 last:pb-0 border-b last:border-b-0"}>
        <MultiSelectOptionElement
          key="create-new-option"
          isSingleSelect={isSingleSelect}
          name={name}
          item={{ value: searchText }}
          onChange={onSelectCreate}
        >
          {formatCreateLabel(searchText)}
        </MultiSelectOptionElement>
      </div>
    ),
    [formatCreateLabel, isSingleSelect, name, onSelectCreate, searchText]
  )

  const renderSelectOption = useCallback(
    (option: MultiSelectOption) => (
      <MultiSelectOptionElement
        key={option.id}
        isSingleSelect={isSingleSelect}
        name={name}
        item={option}
        disabled={option?.disabled}
      >
        {(option.template || RowTemplate)(option)}
      </MultiSelectOptionElement>
    ),
    [isSingleSelect, name]
  )

  return (
    <div className={classNames(containerClassName)}>
      {label && (
        <Label className={labelClassName} htmlFor={field.name} isRequired={required}>
          {label}
        </Label>
      )}
      <div
        onClick={() => {
          if (!disabled) setModalIsOpen(true)
        }}
        className={classNames("flex items-center rounded border border-gray-400", meta.error && "border-red-600")}
        ref={inputRef}
      >
        {Icon && (
          <div className={"flex justify-center items-center rounded-l border-gray-400 border-r bg-gray-50 h-10 w-10"}>
            <Icon className="h-6 w-6 text-gray-500" />
          </div>
        )}
        <MultiSelectInputBar
          error={Boolean(meta.error)}
          disabled={disabled}
          isSingleSelect={isSingleSelect}
          label={selectedLabel || label || "option"}
          value={searchText}
          placeholder={placeholder}
          onChange={(event) => {
            if (!modalIsOpen) setModalIsOpen(true)
            setSearchText(event.target.value)
          }}
          onClear={onClearInput}
          selectedTemplate={selectedTemplate}
          modalIsOpen={modalIsOpen}
          selectedItems={selectedItems}
        />
      </div>
      {modalIsOpen && (
        <MultiSelectBody ref={bodyRef}>
          <MultiSelectTable>
            {matchedOptions.flat().length ? (
              <>
                {!isSingleSelect && withSelectAll && (
                  <SelectAll
                    name={name}
                    matchedOptions={matchedOptions}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                )}
                {isGrouped ? (
                  (matchedOptions as MultiSelectOption[][]).map((optionGroup, i) => (
                    <div key={`itemGroup-${i}`} className={"py-2 last:pb-0 border-b last:border-b-0"}>
                      {optionGroupHeadingTemplate && (
                        <div className={"ml-4 mb-2 mt-2 uppercase text-blue-500 font-semibold text-sm"}>
                          {optionGroupHeadingTemplate(optionGroupTitles[i])}
                        </div>
                      )}
                      {optionGroup.map(renderSelectOption)}
                    </div>
                  ))
                ) : (
                  <div className={"py-2 last:pb-0 border-b last:border-b-0"}>
                    {(matchedOptions as MultiSelectOption[]).map(renderSelectOption)}
                  </div>
                )}
                {isCreatable && searchText && !matched && renderCreateNewOption}
              </>
            ) : isCreatable && searchText && !matched ? (
              renderCreateNewOption
            ) : (
              <div className="p-3 w-full italic text-gray-400 text-sm">
                No {pluralize(selectedLabel || label || "option", 0)} available to assign
              </div>
            )}
          </MultiSelectTable>
        </MultiSelectBody>
      )}
      {withErrorHandling && <div className={"font-light text-xs text-red-600 h-5"}>{meta.error && meta.error}</div>}
    </div>
  )
}
