import { Transition } from "@headlessui/react"
import { forwardRef, ReactNode } from "react"
import { createPortal } from "react-dom"
import { classNames } from "../../../helpers/classNames"

type Props = {
  children: ReactNode
  className?: string
}

export const MultiSelectBody = forwardRef<HTMLDivElement, Props>(({ children, className = "" }, containerRef) => {
  return createPortal(
    <div className={"absolute z-50"} ref={containerRef}>
      <Transition
        appear={true}
        show={true}
        enter="transition ease-out duration-75"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={classNames(
            `absolute block w-full drop-shadow-md mt-1 pb-1 border-[1px] border-gray-200 bg-white rounded-md max-h-[40vh] overflow-y-auto transition-all`,
            className
          )}
        >
          {children}
        </div>
      </Transition>
    </div>,
    document.body
  )
})

MultiSelectBody.displayName = "MultiSelectBody"
