import { Button, Grid, LinearProgress, TextField as MuiTextField, Radio, Typography } from "@mui/material"
import { format } from "date-fns"
import { Form, Formik, useFormikContext } from "formik"
import { FC, useState } from "react"
import { BiCheckCircle } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  Asset,
  AssetAssignableType,
  AssetInspectionTemplateAssignments,
  AssetReportTemplate,
  useAssetCreateMutation,
  useAssetEditMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { AssetCategory } from "../../../graphql/generated/gql/graphql"
import { ShowForCategory, assetCategoryOptions } from "../../../helpers/assetCategory"
import { classNames } from "../../../helpers/classNames"
import { dataURLToBlob } from "../../../helpers/dataURLToBlob"
import { fileUpload } from "../../../helpers/fileUpload"
import { formatMoney } from "../../../helpers/formatMoney"
import { formatNumberFromMoney } from "../../../helpers/formatNumberFromMoney"
import { getDataUrlForImage } from "../../../helpers/getDataUrlForImage"
import { getDateFromDateString } from "../../../helpers/getDateFromDateString"
import { vinLookup } from "../../../helpers/vinLookup"
import { useHandleError } from "../../../hooks/useHandleError"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { PickPlus } from "../../../types/helpers"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { ButtonFilled, ButtonHollow, H5 } from "../../Elements"
import { ChipSelect } from "../../Formik/ChipSelect"
import { DatePicker } from "../../Formik/DatePicker"
import { MultiSelect, MultiSelectOption } from "../../Formik/MultiSelect/MultiSelect"
import AssetWithAssigneeRow from "../../Formik/MultiSelect/Rows/AssetWithAssigneeRow"
import UserWithTaskRow from "../../Formik/MultiSelect/Rows/UserWithTaskRow"
import { RadioGroup } from "../../Formik/RadioGroup"
import { TextField } from "../../Formik/TextField"
import { ImageUpload } from "../../ImageUpload"
import { successSnack } from "../../Notistack/ThemedSnackbars"
import { ProjectBadge } from "../../ProjectBadge"
import { DrawerFooter } from "../Drawer/DrawerFooter"
import { AssetInspectionForm } from "./CreateOrEditAssetForm/AssetInspectionForm"
import {
  InventoryRequirementsForm,
  handleInventoryRequirementsSubmission,
} from "./CreateOrEditAssetForm/InventoryRequirementsForm"
import { InspectionRequirementsValidationSchema, InventoryRequirementsValidationSchema } from "./validationSchemas"

// @todo - We should try to use the generated types for this.
// I spent some time working on it, but it's not trivial.
type Values = {
  name: string
  category: AssetCategory
  companyAssetNumber: string
  assignableType: Asset["assignableType"]
  assignableId: string[]
  projectId: string[]
  ownershipType: Asset["ownershipType"]
  rentalAgreement: {
    startOn?: Date | string
    endOn?: Date | string
    rate?: {
      daily?: string
      weekly?: string
      monthly?: string
    }
  }
  purchaseDetails?: {
    date?: Date | string
    price?: string
  }
  manufacturer: {
    id?: string | null
    make?: string | null
    name?: string | null
    year?: string
    model?: string | null
  }
  vendorContact: Asset["vendorContact"]
  inspectionRequirements: {
    assetReportTemplateId?: string
    intervalInSeconds?: string
    startDate?: Date
  }
  inventoryRequirements: {
    photoRequired?: boolean
    intervalInSeconds?: string
  }
  assetGroupId?: string[]
  groupQuantity?: string
  isAssetGroup: boolean
}

type InspectionTemplatesAssignments = (PickPlus<
  AssetInspectionTemplateAssignments,
  "id" | "assetId" | "intervalInSeconds" | "lastReportedAt" | "startDate"
> & { inspectionTemplate: PickPlus<AssetReportTemplate, "id" | "name"> })[]

type Props = {
  asset?: PickPlus<
    Asset,
    | "id"
    | "imageUrl"
    | "assignableType"
    | "assignableId"
    | "name"
    | "assetGroupId"
    | "companyAssetNumber"
    | "groupQuantity"
    | "isAssetGroup"
    | "manufacturer"
    | "inventoryRequirements"
    | "ownershipType"
    | "purchaseDetails"
    | "rentalAgreement"
    | "vendorContact"
    | "active"
    | "activeChangedAt"
    | "assetChildCount"
    | "assetGroupMemberCount"
    | "category"
  > & {
    inspectionTemplatesAssignments: InspectionTemplatesAssignments
  }
  onCancel: () => void
  onSuccess: () => void
}

type UniqueIdErrors = {
  companyAssetNumber?: string
  manufacturer?: { id?: string }
}

const CreateOrEditAssetFormQuery = graphql(`
  query CreateOrEditAssetFormQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      manufacturer {
        id
      }
    }

    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
    }

    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
    }
  }
`)

const AssignableTaskQuery = graphql(`
  query AssignableTaskQuery($projectId: String!) {
    taskList(projectId: $projectId, archived: false) {
      taskId
      taskGroupId
      name
    }
  }
`)

export const CreateOrEditAssetForm: FC<Props> = ({ asset, onCancel, onSuccess }) => {
  const currentUser = useCurrentUser()

  const [{ error, fetching: editIsFetching }, editAssetMutation] = useAssetEditMutation()
  const [{ error: createError, fetching: createIsLoading }, createAssetMutation] = useAssetCreateMutation()
  useHandleError(error, "There was an error saving the asset.")
  useHandleError(createError, "There was an error saving the asset.")

  const [assetImageSrc, setAssetImageSrc] = useState<string>("")
  const [initialAssetImageSrc, setInitialAssetImageSrc] = useState<string>(asset?.imageUrl || "")
  const [taskQueryVariables, setTaskQueryVariables] = useState({ projectId: "" })

  const [singleOrMultiple, setSingleOrMultiple] = useState<"single" | "multiple">("single")

  const isAssetCreate = !asset

  const handleValidationErrors = async (message: string, setErrors: (args: UniqueIdErrors) => void) => {
    let error: UniqueIdErrors = {}
    const [errorType] = message.split(":")

    switch (errorType) {
      case "DUPLICATE_VIN":
        error.manufacturer = { id: "This VIN / Serial number is already in use by another asset" }
        setErrors(error)
        break
      case "DUPLICATE_ASSET_NUMBER":
        error.companyAssetNumber = "This asset number is already in use by another asset"
        setErrors(error)
        break
      default:
        break
    }
  }

  const [{ data: queryData, fetching: assetsIsLoading, error: assetsError }] = useQuery({
    query: CreateOrEditAssetFormQuery,
  })
  const [{ data: tasksData, fetching: tasksIsLoading, error: tasksError }, fetchTasksByProjectId] = useQuery({
    query: AssignableTaskQuery,
    variables: taskQueryVariables,
    pause: !taskQueryVariables.projectId,
  })

  const assets = queryData?.assets || []
  const users = queryData?.usersList || []
  const projects = queryData?.projectsByStatus || []
  const tasks = tasksData?.taskList || []

  useHandleError(assetsError, "There was an error loading assets.")
  useHandleError(tasksError, "There was an error loading tasks.")

  const commonValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required").label("Name"),

    category: Yup.mixed<AssetCategory>().oneOf(Object.values(AssetCategory), "Invalid Category").required(),

    companyAssetNumber: Yup.string()
      .trim()
      .label("Company asset number")
      .nullable()
      .test("isUnique", "There is another asset with this number", (companyAssetNumber) =>
        assets
          .filter((a) => (asset ? a.id !== asset.id : true))
          .every((asset) => asset?.companyAssetNumber !== companyAssetNumber)
      ),
    projectId: Yup.array().required("Required"),
    groupQuantity: Yup.number()
      .integer()
      .moreThan(-1)
      .max(10000)
      .when("assetGroupId", {
        is: (ids: (string | undefined)[]) => ids.some((id) => !!id),
        then: (schema) => schema.oneOf([undefined], "Quantity cannot be specified on creation of a linked asset"),
      }),
    inventoryRequirements: InventoryRequirementsValidationSchema,
    inspectionRequirements: InspectionRequirementsValidationSchema,
    manufacturer: Yup.object().shape({
      id: Yup.string()
        .trim()
        .label("Vin / Serial number")
        .nullable()
        .test("isUnique", "There is another asset with this VIN / Serial number", (id) =>
          assets
            .filter((a) => (asset ? a.id !== asset.id : true))
            .every((asset) => (id ? asset?.manufacturer?.id !== id : true))
        ),
      year: Yup.string().label("Year").nullable(),
      make: Yup.string().trim().label("Manufacturer").nullable(),
      model: Yup.string().trim().label("Model").nullable(),
    }),
    ownershipType: Yup.string().trim().required("Required").label("Ownership type"),
    purchaseDetails: Yup.object().shape({
      date: Yup.date().label("Purchase date").nullable(),
      price: Yup.string().label("Purchase price").nullable(),
    }),
    rentalAgreement: Yup.object().when("ownershipType", {
      is: "OWN",
      then: (schema) => schema.shape({}).notRequired(),
      otherwise: (schema) =>
        schema.shape({
          startOn: Yup.date().label("Start date").nullable(),
          endOn: Yup.date().label("End date").nullable().default(null),
          rate: Yup.object()
            .shape({
              daily: Yup.string().label("Daily rate").nullable().default(null),
              weekly: Yup.string().label("Daily rate").nullable().default(null),
              monthly: Yup.string().label("Daily rate").nullable().default(null),
            })
            .nullable(),
        }),
    }),
    vendorContact: Yup.object().shape({
      name: Yup.string().trim().label("Name").nullable(),
      email: Yup.string().trim().label("Email").nullable(),
      phone: Yup.string().trim().label("Phone").nullable(),
    }),
  })

  const validationSchema =
    singleOrMultiple === "single"
      ? commonValidationSchema.concat(
          Yup.object().shape({
            assignableId: Yup.array()
              .of(Yup.string().required("Required"))
              .length(1, "Required")
              .required("Required")
              .label("Assignee"),
          })
        )
      : commonValidationSchema

  const inspectionAssignment = asset?.inspectionTemplatesAssignments?.find((x) => x.assetId === asset.id)

  const initialValues = {
    assetGroupId: asset?.assetGroupId ? [asset.assetGroupId] : [""],
    assignableId: asset?.assignableId ? [asset.assignableId] : [""],
    assignableType: asset?.assignableType || AssetAssignableType.User,
    companyAssetNumber: asset?.companyAssetNumber || "",
    groupQuantity: asset?.groupQuantity?.toString() || "1",
    isAssetGroup: asset?.isAssetGroup || false,
    name: asset?.name || "",
    projectId: [""],
    inspectionRequirements: {
      intervalInSeconds: inspectionAssignment?.intervalInSeconds?.toString() || undefined,
      startDate: inspectionAssignment?.startDate || undefined,
      inspectionRequired: false,
    },
    inventoryRequirements: asset?.inventoryRequirements
      ? {
          photoRequired: asset.inventoryRequirements.photoRequired || false,
          intervalInSeconds: asset.inventoryRequirements.intervalInSeconds?.toString(),
        }
      : { intervalInSeconds: undefined, photoRequired: false },
    manufacturer: { ...asset?.manufacturer, year: asset?.manufacturer?.year?.toString() || "" } || {
      year: "",
    },
    ownershipType: asset?.ownershipType || "OWN",
    purchaseDetails: asset?.purchaseDetails
      ? {
          date: asset.purchaseDetails.date ? getDateFromDateString(asset.purchaseDetails.date) : "",
          price: formatMoney(asset.purchaseDetails.price),
        }
      : {},
    rentalAgreement: asset?.rentalAgreement
      ? {
          startOn: asset.rentalAgreement.startOn ? getDateFromDateString(asset.rentalAgreement.startOn) : "",
          endOn: asset.rentalAgreement.endOn ? getDateFromDateString(asset.rentalAgreement.endOn) : "",
          rate: {
            daily: formatMoney(asset.rentalAgreement?.rate?.daily),
            weekly: formatMoney(asset.rentalAgreement?.rate?.weekly),
            monthly: formatMoney(asset.rentalAgreement?.rate?.monthly),
          },
        }
      : {
          startOn: "",
          endOn: "",
          rate: {
            daily: "",
            weekly: "",
            monthly: "",
          },
        },
    vendorContact: asset?.vendorContact || {},
    category: asset?.category || AssetCategory.Other,
  }

  const onSubmit = async (values: Values, { setErrors }: { setErrors: (args: UniqueIdErrors) => void }) => {
    const {
      name,
      category,
      assignableId,
      assignableType,
      companyAssetNumber,
      groupQuantity,
      ownershipType,
      vendorContact,
    } = values

    // @ts-ignore
    delete vendorContact["__typename"]

    const inspectionRequirements = values.inspectionRequirements.assetReportTemplateId
      ? [
          {
            assetReportTemplateId: values.inspectionRequirements.assetReportTemplateId,
            intervalInSeconds: Number(values.inspectionRequirements.intervalInSeconds) || undefined,
            startDate: values.inspectionRequirements?.startDate,
          },
        ]
      : []

    const inventoryRequirements: Asset["inventoryRequirements"] | undefined =
      values.inventoryRequirements && handleInventoryRequirementsSubmission(values.inventoryRequirements)
    const purchaseDetails: Asset["purchaseDetails"] | undefined =
      ownershipType === "OWN"
        ? {
            date:
              values.purchaseDetails?.date && values.purchaseDetails.date instanceof Date
                ? format(values.purchaseDetails.date, "yyyy-MM-dd")
                : "",
            price: values.purchaseDetails?.price ? formatNumberFromMoney(values.purchaseDetails.price) : undefined,
          }
        : undefined

    const rentalAgreement: Asset["rentalAgreement"] | undefined =
      ownershipType === "RENT"
        ? {
            startOn: values.rentalAgreement.startOn
              ? format(new Date(values.rentalAgreement.startOn), "yyyy-MM-dd")
              : "",
            endOn: values.rentalAgreement.endOn ? format(new Date(values.rentalAgreement.endOn), "yyyy-MM-dd") : "",
            rate: {
              daily: formatNumberFromMoney(values.rentalAgreement?.rate?.daily),
              weekly: formatNumberFromMoney(values.rentalAgreement?.rate?.weekly),
              monthly: formatNumberFromMoney(values.rentalAgreement?.rate?.monthly),
            },
          }
        : undefined

    const manufacturer = values.manufacturer
      ? {
          id: values.manufacturer.id || undefined,
          make: values.manufacturer.make,
          name: values.manufacturer.name,
          year: values.manufacturer.year ? parseInt(values.manufacturer.year) : undefined,
          model: values.manufacturer.model,
        }
      : {}

    const parsedGroupQuantity = groupQuantity ? +groupQuantity : 0
    const isAssetGroup = parsedGroupQuantity > 1

    if (asset?.id) {
      let photoId = !initialAssetImageSrc ? "" : undefined
      if (assetImageSrc) {
        const imageBlob = dataURLToBlob(assetImageSrc)
        const uploadedFile = await fileUpload(`asset-image/${asset.id}`, "image/webp", imageBlob)
        photoId = uploadedFile?.fileId || undefined
      }

      editAssetMutation({
        id: asset.id,
        name,
        category,
        companyAssetNumber: companyAssetNumber || undefined,
        ownershipType,
        photoId,
        inspectionRequirements,
        inventoryRequirements,
        manufacturer,
        purchaseDetails,
        rentalAgreement,
        vendorContact,
      }).then((result) => {
        if (result.error) {
          handleValidationErrors(result.error.message, setErrors)
        }

        onSuccess()
        setAssetImageSrc("")
      })
    } else {
      createAssetMutation({
        name,
        category,
        companyAssetNumber,
        assignableId: parseInt(groupQuantity || "1") > 1 ? currentUser.id : assignableId[0],
        assignableType: parseInt(groupQuantity || "1") > 1 ? AssetAssignableType.User : assignableType,
        groupQuantity: parsedGroupQuantity,
        isAssetGroup,
        ownershipType,
        rentalAgreement,
        purchaseDetails,
        inspectionRequirements,
        inventoryRequirements,
        manufacturer,
        vendorContact,
      }).then(async (result) => {
        if (result.error) {
          handleValidationErrors(result.error.message, setErrors)
        } else {
          successSnack("Asset created successfully")

          let photoId = !initialAssetImageSrc ? "" : undefined
          if (assetImageSrc) {
            const imageBlob = dataURLToBlob(assetImageSrc)
            const uploadedFile = await fileUpload(
              `asset-image/${result.data?.insertOneAsset?.id}`,
              "image/webp",
              imageBlob
            )
            photoId = uploadedFile?.fileId || undefined

            editAssetMutation({
              id: result.data?.insertOneAsset?.id!,
              photoId,
            })
          }

          onSuccess()
          setAssetImageSrc("")
        }
      })
    }
  }

  return (
    <Formik
      initialTouched={{
        companyAssetNumber: true,
        manufacturer: {
          id: true,
        },
      }}
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, handleChange, setFieldValue, touched, values }) => (
        <Form className="grid gap-y-12 transition-all max-w-2xl">
          <DevelopmentFeatureFlag name="Asset Category">
            <div>
              <SectionTitle label="Category" />
              <ChipSelect name="category" options={assetCategoryOptions} />
            </div>
          </DevelopmentFeatureFlag>

          {isAssetCreate && (
            <ShowForCategory category={values.category} showFor={[AssetCategory.Vehicles, AssetCategory.Trailers]}>
              <VinLookup />
            </ShowForCategory>
          )}

          <div>
            {isAssetCreate && <SectionTitle label="Basic Info" />}
            <div>
              <ImageUpload
                onFileSelected={([file]) => {
                  if (file) {
                    getDataUrlForImage(file, (dataUrl) => setAssetImageSrc(dataUrl), 600, 600)
                  }
                }}
                onDelete={() => {
                  setAssetImageSrc("")
                  setInitialAssetImageSrc("")
                }}
                imageSrc={assetImageSrc}
                initialImageSrc={initialAssetImageSrc}
                accept={"accept"}
              />

              <TextField
                fullWidth
                label="Asset Name"
                name="name"
                required
                value={values.name}
                sx={{ marginTop: "1.5rem" }}
              />

              <TextField fullWidth label="Asset number" name="companyAssetNumber" />
            </div>
          </div>

          {!(asset && asset.assignableId) && (
            <>
              {/* only show this if we are creating an asset */}
              {isAssetCreate && (
                <ShowForCategory
                  category={values.category}
                  showFor={[AssetCategory.Tools, AssetCategory.Electronics, AssetCategory.Other]}
                >
                  <div>
                    <SectionTitle label="Asset Quantity" />

                    <div className="grid gap-8">
                      <div className="grid gap-2">
                        <label
                          className={classNames(
                            "py-6 px-4 border rounded-lg flex items-start cursor-pointer",
                            singleOrMultiple === "single" ? "border-blue-600" : "border-gray-400"
                          )}
                        >
                          <Radio
                            checked={singleOrMultiple === "single"}
                            onChange={() => {
                              setFieldValue("groupQuantity", 1)
                              setSingleOrMultiple("single")
                            }}
                            value="single"
                            name="singleOrGroup"
                            sx={{ marginTop: "-9px" }}
                          />
                          <div className="grid gap-1">
                            <p className="text-base">Single</p>
                            <p className="text-gray-400 text-sm">This is an individual asset.</p>
                          </div>
                        </label>
                        <label
                          className={classNames(
                            "py-6 px-4 border rounded-lg flex items-start cursor-pointer",
                            singleOrMultiple === "multiple" ? "border-blue-600" : "border-gray-400"
                          )}
                        >
                          <Radio
                            checked={singleOrMultiple === "multiple"}
                            onChange={() => {
                              if (parseInt(`${values.groupQuantity}`) === 1) {
                                setFieldValue("groupQuantity", 2)
                              }
                              setSingleOrMultiple("multiple")
                            }}
                            value="multiple"
                            name="singleOrGroup"
                            sx={{ marginTop: "-9px" }}
                          />
                          <div className="grid gap-1">
                            <p className="text-base">Multiple</p>
                            <p className="text-gray-400 text-sm">This is an asset with quantities.</p>
                          </div>
                        </label>
                      </div>

                      {singleOrMultiple === "multiple" && (
                        <div className="grid gap-2">
                          <TextField
                            error={touched.groupQuantity && Boolean(errors.groupQuantity)}
                            fullWidth
                            helperText={touched.groupQuantity && errors.groupQuantity}
                            label="Quantity"
                            name="groupQuantity"
                            onChange={handleChange}
                            type="number"
                            inputProps={{
                              min: 2,
                              step: 1,
                            }}
                            value={values.groupQuantity}
                            variant="outlined"
                          />
                          <p className="text-sm text-gray-400">
                            Adding a quantity will allow you to make multiple assignments of this asset.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </ShowForCategory>
              )}

              {values.groupQuantity && parseInt(values.groupQuantity || "1") <= 1 && (
                <div>
                  <SectionTitle label="Assignment" />

                  <div className="grid gap-8">
                    <RadioGroup
                      name="assignableType"
                      options={[
                        {
                          value: "User",
                          label: "User",
                        },
                        {
                          value: "Task",
                          label: "Project",
                        },
                        {
                          value: "Asset",
                          label: "Other asset",
                        },
                      ]}
                    />

                    {values.assignableType === AssetAssignableType.Asset && (
                      <div className={classNames("grow", assetsIsLoading && "opacity-50")}>
                        <MultiSelect
                          containerClassName="grow"
                          selectedLabel="assignee"
                          options={[
                            assets?.map((asset) => {
                              return {
                                id: asset.id,
                                label: `${asset.name}`,
                                searchableTextString: `${asset.name} ${asset.companyAssetNumber}`,
                                value: asset.id,
                                template: (_item: MultiSelectOption) => <AssetWithAssigneeRow asset={asset} />,
                              }
                            }),
                          ]}
                          optionGroupHeadingTemplate={(title) => title}
                          name="assignableId"
                          isSingleSelect
                        />
                        <p className="text-gray-400 text-sm">
                          This assignment will link the asset to the other asset and will follow the other asset.
                        </p>
                      </div>
                    )}

                    {values.assignableType === AssetAssignableType.Task && (
                      <div>
                        <div className={classNames("grow", assetsIsLoading && "opacity-50")}>
                          <MultiSelect
                            containerClassName="grow"
                            placeholder="Select a project"
                            options={[
                              projects.map((project) => {
                                return {
                                  id: project.id,
                                  label: project.name,
                                  searchableTextString: project.name,
                                  value: project.id,
                                  template: (_item: MultiSelectOption) => <ProjectBadge project={project} noHover />,
                                }
                              }),
                            ]}
                            optionGroupHeadingTemplate={(title) => title}
                            name={"projectId"}
                            isSingleSelect={true}
                            onChange={(values) => {
                              setTaskQueryVariables({ projectId: values[0] })
                              fetchTasksByProjectId()
                            }}
                          />
                        </div>

                        <div className={classNames("grow", tasksIsLoading && "opacity-50")}>
                          <MultiSelect
                            containerClassName="grow"
                            disabled={!taskQueryVariables.projectId}
                            placeholder="Select a task"
                            options={[
                              tasks.map((task) => {
                                return {
                                  id: task.taskId || task.taskGroupId || "",
                                  label: task.name,
                                  searchableTextString: task.name,
                                  value: task.taskId || task.taskGroupId || "",
                                  template: (_item: MultiSelectOption) => <div>{task.name}</div>,
                                }
                              }),
                            ]}
                            optionGroupHeadingTemplate={(title) => title}
                            name={"assignableId"}
                            isSingleSelect={true}
                          />
                        </div>
                        <p className="text-gray-400 text-sm">
                          This assignment will link the asset to the task and will follow the task.
                        </p>
                      </div>
                    )}

                    {values.assignableType === AssetAssignableType.User && (
                      <div className={classNames("grow", assetsIsLoading && "opacity-50")}>
                        <MultiSelect
                          containerClassName="grow"
                          selectedLabel="assignee"
                          options={[
                            users.map((user) => {
                              return {
                                id: user.id,
                                label: `${user.firstName} ${user.lastName}`,
                                searchableTextString: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                                template: (_item: MultiSelectOption) => <UserWithTaskRow user={user} />,
                              }
                            }),
                          ]}
                          optionGroupHeadingTemplate={(title) => title}
                          name="assignableId"
                          isSingleSelect
                        />
                        <p className="text-gray-400 text-sm">
                          This assignment will link the asset to the user and will follow the user.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {values.groupQuantity && parseInt(values.groupQuantity || "1") <= 1 && (
            <>
              <div>
                <SectionTitle label="Other Details" />

                <TextField
                  fullWidth
                  label={
                    [AssetCategory.Vehicles, AssetCategory.Trailers].includes(values.category)
                      ? "VIN #"
                      : "Vin or serial #"
                  }
                  name="manufacturer.id"
                />

                <TextField
                  fullWidth
                  label="Year"
                  name="manufacturer.year"
                  inputProps={{
                    min: 1900,
                    max: new Date().getFullYear() + 2,
                  }}
                />

                <TextField fullWidth label="Manufacturer" name="manufacturer.make" />

                <TextField fullWidth label="Model" name="manufacturer.model" />
              </div>

              <div>
                <div>
                  <SectionTitle label="Owned / Rented" />

                  <RadioGroup
                    name="ownershipType"
                    options={[
                      {
                        value: "OWN",
                        label: "Owned",
                      },
                      {
                        value: "RENT",
                        label: "Rented",
                      },
                    ]}
                  />

                  {values.ownershipType === "OWN" && (
                    <div className="mt-4">
                      <TextField fullWidth label="Purchase Price" name="purchaseDetails.price" />

                      <DatePicker
                        label="Purchase Date"
                        name="purchaseDetails.date"
                        format="MM/dd/yyyy"
                        sx={{ width: "100%" }}
                      />
                    </div>
                  )}

                  {values.ownershipType === "RENT" && (
                    <div className="mt-4">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Rental start on"
                            name="rentalAgreement.startOn"
                            format="MM/dd/yyyy"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            sx={{ width: "100%", marginBottom: "1rem" }}
                            label="Rental end on"
                            name="rentalAgreement.endOn"
                            format="MM/dd/yyyy"
                          />
                        </Grid>
                      </Grid>
                      <div className="grid lg:grid-cols-3 gap-4">
                        <TextField
                          label="Daily rate"
                          name="rentalAgreement.rate.daily"
                          placeholder="Daily rate"
                          type="money"
                        />
                        <TextField
                          label="Weekly rate"
                          name="rentalAgreement.rate.weekly"
                          placeholder="Weekly rate"
                          type="money"
                        />
                        <TextField
                          label="Monthly rate"
                          name="rentalAgreement.rate.monthly"
                          placeholder="4-week rate"
                          type="money"
                        />
                      </div>

                      <TextField fullWidth label="Vendor Name" name="vendorContact.name" placeholder="Vendor name" />

                      <TextField
                        fullWidth
                        label="Vendor Email"
                        name="vendorContact.email"
                        type="email"
                        placeholder="vendor@example.com"
                      />
                      <TextField
                        fullWidth
                        label="Vendor Phone"
                        name="vendorContact.phone"
                        placeholder="(888) 888-8888"
                        type="tel"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div>
            <SectionTitle label="Inventory Checklist" />
            <InventoryRequirementsForm isCheckedByDefault={!!values.inventoryRequirements?.intervalInSeconds} />
          </div>

          <div>
            <SectionTitle label="Inspection Checklist" />
            <AssetInspectionForm asset={asset} />
          </div>

          <DrawerFooter>
            <div className="flex flex-wrap items-center gap-x-8 gap-y-3">
              <ButtonFilled type="submit" disabled={createIsLoading || editIsFetching}>
                Save asset
              </ButtonFilled>
              <ButtonHollow type="button" onClick={onCancel}>
                Cancel
              </ButtonHollow>
            </div>
          </DrawerFooter>
        </Form>
      )}
    </Formik>
  )
}

const VinLookup = ({}) => {
  const [vin, setVin] = useState("")
  const [errorText, setErrorText] = useState("")
  const [isFilledFromNhtsaResponse, setIsFilledFromNhtsaResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { setFieldValue } = useFormikContext()

  const onSubmit = async () => {
    if (!vin) {
      setErrorText("Please enter a VIN")
      return
    }
    if (vin.length !== 17) {
      setErrorText("VIN must be 17 characters")
      return
    }

    setErrorText("")
    setIsFilledFromNhtsaResponse(false)

    setIsLoading(true)

    try {
      const data = await vinLookup(vin)

      // find error code
      const errorText = data.Results.find((result) => result.Variable === "Error Text")?.Value
      if (errorText && errorText !== "0 - VIN decoded clean. Check Digit (9th position) is correct") {
        throw new Error("Invalid VIN. Please check and try again.")
      }

      // find make
      const make = data.Results.find((result) => result.Variable === "Make")?.Value
      setFieldValue("manufacturer.make", make)

      // find model
      const model = data.Results.find((result) => result.Variable === "Model")?.Value
      setFieldValue("manufacturer.model", model)

      // find trim
      const trim = data.Results.find((result) => result.Variable === "Trim")?.Value
      setFieldValue("manufacturer.trim", trim)

      // find year
      const year = data.Results.find((result) => result.Variable === "Model Year")?.Value
      setFieldValue("manufacturer.year", year)

      setFieldValue("manufacturer.id", vin)

      setFieldValue("name", `${year} ${make} ${model} ${trim}`)
      setIsFilledFromNhtsaResponse(true)
    } catch (error: any) {
      setErrorText(
        error.message || "There was an error communicating with the national database. Please try again later."
      )
    }

    setIsLoading(false)
  }

  return (
    <div className={classNames(isLoading && "opacity-50", "transition-opacity")}>
      <SectionTitle label="VIN Lookup" />
      <div className="grid lg:grid-cols-5 gap-4">
        <div className="grid gap-2 lg:col-span-4">
          <MuiTextField
            error={!!errorText}
            fullWidth
            helperText={errorText}
            label="VIN Lookup"
            name="vin"
            onChange={(e) => setVin(e.target.value)}
            value={vin}
            variant="outlined"
          />
        </div>
        <div>
          <Button disabled={!vin} fullWidth variant="contained" color="secondary" onClick={onSubmit}>
            Lookup
          </Button>
        </div>
      </div>
      <div className="py-1">{isLoading ? <LinearProgress /> : <div className="h-1" />}</div>
      <div>
        {isFilledFromNhtsaResponse && (
          <div className="flex items-center gap-2">
            <BiCheckCircle className="text-blue-600" />
            <Typography color="primary">Filled 5 fields from VIN lookup</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

type SectionTitleProps = {
  label?: string
}

const SectionTitle: FC<SectionTitleProps> = ({ label }) => {
  return (
    <>
      {label && <H5>{label}</H5>}
      <hr className="mb-8" />
    </>
  )
}
