import { FC } from "react"
import { BiCar, BiPackage } from "react-icons/bi"
import { classNames } from "../helpers/classNames"
import { SelectableAsset } from "../types/Asset"

type AssetImageProps = {
  asset?: Omit<SelectableAsset, "assignableId">
  isGroupedAsset?: boolean
  height?: string
  width?: string
  padding?: string
  placeholderImageBgColor?: string
  placeholderImageTextColor?: string
  defaultImage?: "car" | "package"
}

export const AssetImage: FC<AssetImageProps> = ({
  asset,
  height = "h-full",
  width = "w-full",
  padding = "p-2",
  placeholderImageBgColor = "bg-gray-100",
  placeholderImageTextColor = "text-gray-300",
  defaultImage = "car",
}) => {
  const iconClassNames = "w-full h-full text-gray-800 bg-gray-200 rounded p-2"
  return (
    <div className="shrink-0">
      {asset?.imageUrl && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          alt={asset?.name || "Asset"}
          className={classNames("rounded z-0 object-cover", width, height)}
          src={asset.imageUrl}
        />
      )}

      {!asset?.imageUrl && (
        <div
          className={classNames(`rounded`, width, height, padding, placeholderImageTextColor, placeholderImageBgColor)}
        >
          {defaultImage === "car" ? <BiCar className={iconClassNames} /> : <BiPackage className={iconClassNames} />}
        </div>
      )}
    </div>
  )
}
