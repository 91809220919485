import { ResponseError } from "./api/errors"

function isNil(value: any) {
  return value === undefined || value === null
}

export function requireFields<T>(requiredFields: string[], ...args: any[]): T {
  const obj = args.reduce((a, e) => ({ ...a, ...e }), {})
  for (let key of requiredFields) {
    if (isNil(obj[key])) throw new ResponseError(`missing required field ${key}`, 400)
  }
  return obj
}

export function omitNil<T extends {}>(obj: T) {
  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => value != null))
}

export const pickBy = (object: { [key: string]: any }, predicate = (_k: string, v: any) => v) =>
  Object.fromEntries(Object.entries(object).filter(([k, v]) => predicate(k, v)))

export function groupBy<T, K extends keyof any>(collection: Array<T>, iteratee: (item: T) => K) {
  return collection.reduce((result, value) => {
    const key = iteratee(value)
    if (result.hasOwnProperty(key)) result[key].push(value)
    else result[key] = [value]
    return result
  }, {} as Record<K, T[]>)
}

export function allowedFields(allowedFields: string[], ...args: any[]) {
  const obj = args.reduce((a, e) => ({ ...a, ...e }), {})
  return pickBy(obj, (key) => allowedFields.find((e) => e === key))
}

export function notNull(value: any) {
  return value === null ? undefined : value
}

export function optionalConnect(id: any) {
  if (id) return { connect: { id } }
  return undefined
}

export function connect(id: any) {
  return { connect: { id } }
}

export async function defaultAsync(promise: Promise<any>, defaultValue: any) {
  const data = await promise
  return data || defaultValue
}

export function isOneDimensional(array: any[]) {
  return array.every((entry) => !Array.isArray(entry))
}

export function uniqBy<T>(array: T[], getKey: (e: T) => any) {
  return [...new Map(array.map((x) => [getKey(x), x])).values()]
}

export async function errorData<T>(promise: Promise<T>) {
  try {
    const data = await promise
    return { error: null, data }
  } catch (error) {
    return { error, data: null }
  }
}
