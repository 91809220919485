type NhtsaResult = {
  Value: string
  ValueId: string
  Variable: string
  VariableId: number
}

type NhtsaResponse = {
  Count: number
  Message: string
  SearchCriteria: string
  Results: NhtsaResult[]
}

export const vinLookup = async (vin: string) => {
  const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinExtended/${vin}?format=json`)
  const data: NhtsaResponse = await response.json()

  if (!data.Message) {
    throw new Error("There was an error communicating with the national database. Please try again later.")
  }

  return data
}

export const decodeVinValues = (data: NhtsaResponse) => {
  const results = data.Results

  return {
    additionalErrorText: results.find((result) => result.Variable === "Additional Error Text")?.Value,
    errorCode: results.find((result) => result.Variable === "Error Code")?.Value,
    errorText: results.find((result) => result.Variable === "Error Text")?.Value,
    make: results.find((result) => result.Variable === "Make")?.Value,
    manufacturerName: results.find((result) => result.Variable === "Manufacturer Name")?.Value,
    model: results.find((result) => result.Variable === "Model")?.Value,
    modelYear: results.find((result) => result.Variable === "Model Year")?.Value,
    note: results.find((result) => result.Variable === "Note")?.Value,
    suggestedVin: results.find((result) => result.Variable === "Suggested VIN")?.Value,
    trim: results.find((result) => result.Variable === "Trim")?.Value,
    vehicleDescriptor: results.find((result) => result.Variable === "Vehicle Descriptor")?.Value,
    year: results.find((result) => result.Variable === "Model Year")?.Value,
  }
}

export const getVehicleData = async (vin: string) => {
  const data = await vinLookup(vin)
  return decodeVinValues(data)
}
