import { useField } from "formik"
import { FC, ReactNode } from "react"
import { classNames } from "../../../helpers/classNames"

type Props = {
  children: ReactNode
  item: any
  name: string
  className?: string
  isSingleSelect: boolean
  onChange?: (value: string) => void
  disabled?: boolean
}

export const MultiSelectOptionElement: FC<Props> = ({
  isSingleSelect,
  item,
  children,
  name,
  className,
  onChange,
  disabled = false,
}) => {
  const [field, _meta, helpers] = useField(name)

  return (
    <label
      key={item.value}
      className={classNames(
        "py-2 px-5 group grid items-center grid-cols-12 gap-x-3 text-sm cursor-pointer rounded relative bg-white transition-all ease-in-out duration-200 hover:bg-gray-50 w-full",
        disabled ? "text-gray-400" : ""
      )}
    >
      {isSingleSelect ? (
        <input
          disabled={disabled}
          className={`hidden`}
          onChange={() => {
            helpers.setValue([item.value])
            onChange?.(item.value)
          }}
          type="checkbox"
          value={item.value}
        />
      ) : (
        <input
          checked={!!field.value.find((value: string) => item.value == value)}
          className={classNames(
            `col-span-1 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-400 rounded-sm border-2`,
            !field.value.find((value: string) => item.value == value) && "group-hover:bg-gray-50",
            className
          )}
          onChange={() => {
            const valueExists = field.value.filter((selectedValue: string) => selectedValue === item.value).length
            const filteredValues = field.value.filter((selectedValue: string) => selectedValue != item.value)
            if (valueExists) {
              helpers.setValue(filteredValues)
            } else {
              helpers.setValue([...field.value, item.value])
            }
            onChange?.(item.value)
          }}
          type="checkbox"
          value={item.value}
        />
      )}
      <div className="col-span-11">{children}</div>
    </label>
  )
}
