import { FC } from "react"
import { BiHash, BiLink, BiPurchaseTag, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { Asset } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { PickPlus } from "../../../../types/helpers"
import { AssetImage } from "../../../AssetImage"

type Props = {
  asset: PickPlus<
    Asset,
    | "id"
    | "assetChildCount"
    | "assetGroupId"
    | "assignableId"
    | "assignedUserId"
    | "companyAssetNumber"
    | "groupQuantity"
    | "imageUrl"
    | "isAssetGroup"
    | "name"
    | "ownershipType"
  >
}

const AssetWithAssigneeRow: FC<Props> = ({ asset }) => {
  const AssigneeQuery = graphql(`
    query AssetWithAssigneeRowGetUser($id: String!) {
      user(id: $id) {
        firstName
        lastName
        jobTitle
      }
    }
  `)
  const [{ data: assigneeData }] = useQuery({
    query: AssigneeQuery,
    pause: !asset.assignedUserId,
    variables: {
      id: asset?.assignedUserId || "",
    },
  })

  return (
    <div className="grid grid-cols-12 gap-4 justify-between">
      <div className="col-span-7 flex items-center gap-x-3">
        <AssetImage width="w-12 md:w-10" height="h-12 md:h-10" asset={asset} />
        <div className="grid justify-center content-center gap-y-0.5">
          <div className="cursor-pointer mt-1 truncate leading-tight font-medium md:font-normal">{asset.name}</div>
          <div className="flex gap-2 items-center text-xs text-gray-600 truncate">
            {asset.ownershipType === "RENT" && (
              <div className="flex items-center gap-pt text-orange-600">
                <BiPurchaseTag />
                <div>Rental</div>
              </div>
            )}
            {asset.assetChildCount > 0 && !asset.isAssetGroup && !asset.assetGroupId && (
              <div className="flex items-center gap-pt">
                <BiLink />
                <div>{asset.assetChildCount}</div>
              </div>
            )}
            {asset.groupQuantity && asset.groupQuantity > 1 && (
              <div className="flex items-center gap-pt">
                <BiX />
                <div>{asset.groupQuantity}</div>
              </div>
            )}
            {asset.companyAssetNumber && (
              <div className="flex items-center gap-pt">
                <BiHash />
                <div className="truncate">{asset.companyAssetNumber}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-5 truncate">
        <p className="text-blue-600 leading-tight truncate">
          {assigneeData?.user?.firstName} {assigneeData?.user?.lastName}
        </p>
        <p className="text-gray-400 text-xs leading-tight truncate">{assigneeData?.user?.jobTitle}</p>
      </div>
    </div>
  )
}

export default AssetWithAssigneeRow
